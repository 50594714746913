import {toast} from "react-toastify";
import i18next from "i18next";
import {SessionService} from "../services/SessionService";
import {MSOGAPIClient, MSOGAPIError} from "../utilitis/MSOGAPIClient";
import {SET_MSOG_API_ERROR, UPDATE_PRECHECKLIST} from "../constains/appStateActions";
import {push} from "connected-react-router";


const ATTACHEDIMGRESOLUTION_WIDTH = 600;
const ATTACHEDIMGRESOLUTION_HEIGHT = 800;

export const NETWORK_STATUS_INDEX = 0x1;
export const CLOUD_CONN_STATUS_INDEX = NETWORK_STATUS_INDEX << 1;

export const BACKGROUND_REFRESH_INTERVAL = 10000;

const findPos = (obj) =>{
    let curtop = 0;
    if (obj.offsetParent) {
        do {
            curtop += obj.offsetTop - 10;
        } while (obj === obj.offsetParent);
        return [curtop];
    }
}

export const scrollToElement = (id)=>{
    let scrollElement = document.getElementById(id);
    if(scrollElement){
        window.scrollTo({left:0, top:findPos(scrollElement), behavior:"smooth"})
    }
}

export function fetchLocalStorage(key, initialValue) {

    const item = window.localStorage.getItem(key);
    try {
        // Get from local storage by key

        // Parse stored json or if none return initialValue
        if(item === null || item === undefined){
            return initialValue;
        } else {
            return (typeof item !== 'object') ? JSON.parse(item) : item;
        }

    } catch (error) {
        // If error also return initialValue
        console.log(`fetch local storage ${key} failed, error : ${error}`);
        return item;
    }
}

export function setLocalStorage(key, storedValue) {
    try {
        // Save to local storage
        if (storedValue !== undefined) {
            window.localStorage.setItem(key,  JSON.stringify(storedValue));
        } else {
            window.localStorage.removeItem(key);
        }
    } catch (error) {
        // A more advanced implementation would handle the error case
        storedValue = undefined;
        window.localStorage.removeItem(key);
        console.log(error);
    } finally {
        return storedValue;
    }
}

export function notify(type, message, isLocalised = false) {
    let localised_msg = (isLocalised) ? message : i18next.t(`message:${message}`);
    switch (type) {
        case "error" :
            toast.error(localised_msg, {position: toast.POSITION.BOTTOM_CENTER});
            break;
        default:
            toast.success(localised_msg, {position: toast.POSITION.BOTTOM_CENTER});
            break;
    }
}

export const buildHeader = (result, dispatch) => {
    if (result instanceof MSOGAPIError) {
        notify('error', `refresh_token.${result.code}`);
        dispatch(push("/"));
        return null;
    } else {
        return {
            headers: {
                "Authorization": `Bearer ${result}`
            }
        };
    }
}


export const getUploadFileDataUrl = (a, b , w = ATTACHEDIMGRESOLUTION_WIDTH, h = ATTACHEDIMGRESOLUTION_HEIGHT) => {
    var c = document.createElement("img");
    var d = new FileReader();
    d.onload = function(a) {
        c.src = a.target.result;
        c.onload = function() {
            downscale(c, b, w, h);
        };
    };
    d.readAsDataURL(a);
}

export const downscale = (element, callback, w, h) => {

    const width = w; // after scale
    const height = h; // after sacle
    const orientation = (element.height > element.width) ? "p" : "l";
    const scale = Math.min(width / ((orientation === "p") ? element.width : element.height), height / ((orientation === "l") ? element.width : element.height)); // how much to scale the image to fit
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const  ctx = canvas.getContext("2d");
    if (orientation === "l") {
        ctx.setTransform( 0,scale, -scale , 0, width, 0);
    } else {
        ctx.setTransform( scale, 0, 0, scale, 0, 0);
    }
    ctx.drawImage(element,0,0);
    ctx.setTransform(1,0,0,1,0,0); // restore default
    callback(canvas.toDataURL("image/png"));
}

export function buildMiddlewareActionFromAPI (response, originalAction, notifyError = false) {
    if (response instanceof MSOGAPIError) {
        if ( notifyError ) {
            notify('error', `${originalAction}.${response.code}`)
        }
        return {type: SET_MSOG_API_ERROR, payload: {original: originalAction, code: response.code}};
    } else {
        return {type: originalAction, payload: response};
    }
}

export function globalToLocalFieldGrid(fieldCSS){
    const globalLabelCol = fieldCSS ? Number(fieldCSS.label ?? 1) : 1,
        globalFieldCol = fieldCSS ? Number(fieldCSS.field ?? 1) : 1,
        globalLabelColTablet = fieldCSS ? Number(fieldCSS.label_tablet ?? globalLabelCol) : globalLabelCol,
        globalFieldColTablet  = fieldCSS ? Number(fieldCSS.field_tablet ?? globalFieldCol) : globalFieldCol,
        labelCol = Math.ceil(globalLabelCol / (globalFieldCol + globalLabelCol) * 12),
        fieldCol = 12 - labelCol,
        labelColTablet = Math.ceil(globalLabelColTablet / (globalFieldColTablet + globalLabelColTablet) * 12),
        fieldColTablet = 12 - labelColTablet;
    return [labelCol, fieldCol, labelColTablet, fieldColTablet];
}

export const timeout = async (closure, ms) => await new Promise(resolve => setTimeout(()=>{resolve(closure());}, ms));

export function createAsyncFetchAction(actionType, sessionService, client, apiFunc , callback = undefined, callbackBeforeDispatch = false) {
    return async (dispatch, getState) => {
        client.updateToken(await sessionService.getAccessToken());
        const action = buildMiddlewareActionFromAPI(await apiFunc.call(client), actionType, callback === undefined),
            handlers = callbackBeforeDispatch ? [callback, dispatch] : [dispatch, callback];

        for(const handler of handlers) {
            if (handler) {
                handler(action)
            }
        }
    }
}

export function isServiceAlive(status) {
    return status === (NETWORK_STATUS_INDEX | CLOUD_CONN_STATUS_INDEX);
}

export function isOnline(status) {
    return (status & NETWORK_STATUS_INDEX) > 0;
}

export function isEmpty(value) {
    return value === undefined || value === null || (typeof value === "string" && value.trim().length === 0) || (Array.isArray(value) && value.length === 0 );
}

// Detects if device is in standalone mode
export const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

export const updateState = (state, variableName, newValue) => {
    let newState = {...state};
    newState[variableName] = newValue;
    return state[variableName] !== newValue ? newState : state;
}

export const backToSetting = (callback = undefined) => {
    window.bridge?.post("udocs_goback_setting", {}, function(results, error) {
        if (callback) {
            callback(results, error);
        }
    })
}

export const updateApplayerTracking = (params = {}, callback = undefined) => {
    window.bridge?.post("udocs_update_tracking_config", params, function(results, error) {
        if (callback) {
            callback(results, error);
        }
    })
}

export const setApplayerOrientationLock  = (status = false, callback = undefined) => {
    window.bridge?.post("udocs_set_orientationlock", {on: status}, function(results, error) {
        if (callback) {
            callback(results, error);
        }
    })
}

export const generateValidateParams = (id, required) => {
    const requiredConstrains = required === "" ? 0 : (JSON.parse(required) ?? Number(required));
    var params = {}

    if (requiredConstrains instanceof Object) {

        params.validate = {};
        
        for(const key in requiredConstrains) {

            const variables = requiredConstrains[key].split(":");

            if (key === 'required_if' && variables.length === 3) {
                params.validate[key] = (value, formValues, inputID=id, anotherField=variables[0], anotherFieldExpect=variables[1], valueExpect=variables[2]) => {
                    return formValues[anotherField] !== valueExpect || value === valueExpect;
                };
            }

        }

    } else {
        params['required'] = requiredConstrains === 1;
    }

    return params;
}