import React, {createContext, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector, useStore} from "react-redux";
import {useStateIfMounted} from "use-state-if-mounted";
import {useSessionService} from "./SessionService";
import * as serviceWorker from '../../serviceWorker';

import {
    selectAppSettings,
    selectBackgroundServiceState, selectLocationUploadAt,
    selectLoginState,
    selectOnlineState,
    selectPathName,
    selectQueueSelectedState, selectSessionUpdateTime, selectVehicleCheckedState
} from "../store";
import {MSOGAPIClient, MSOGAPIError} from "../utilitis/MSOGAPIClient";
import {setNewJobCount} from "../reducers/deliveryStateReducer";
import {isEmpty, isServiceAlive} from "../utilitis/helper";
import useGeolocation from "react-hook-geolocation";
import {setLocationUploadAt} from "../reducers/runtimeStateReducer";
import {registerBackgroundSync} from "../../serviceWorker";
import UseLogoutWhenUnAuth from "../components/UseLogoutWhenUnAuth";
import {BACKGROUND_REFRESH_INTERVAL} from "../utilitis/helper";

const MD5 = require("crypto-js/md5");

const fetchNewJobCount = (client) =>{

    const run = async (sessionService, process, params) => {

        if ( sessionService.isLogin() && sessionService.getDeliveredQueue() !== false && sessionService.isVehicleCheckDone()) {
            client.updateToken(await sessionService.getAccessToken());
            const response = await client.getNewJobCount(sessionService.getDeliveredQueue().guid);
            if (! (response instanceof MSOGAPIError)) {
                params.currentStore.dispatch(setNewJobCount(response.count));
            }
        }
        process.isRunning = false;
    }

    return {run};
}


const uploadErrorLogs = (client) =>{

    const run = async (sessionService, process, params) => {
        const errorLogs = await sessionService.geErrorLogs();
        if (errorLogs.length > 0) {
            const response = await client.postErrorLogs(errorLogs);
            if (! (response instanceof MSOGAPIError)) {
                await sessionService.removeErrorLogs(errorLogs);
            }
        }
        process.isRunning = false;
    }

    return {run};
}

const uploadDriverLogs = (client) =>{

    const run = async (sessionService, process, params) => {
        const driverLogs = await sessionService.getDriverLogs(),
            logsInString = JSON.stringify(driverLogs);
        sessionService.logError({stack:"BackgroundSerivce::uploadDriverLogs::" + MD5(logsInString).toString(), message:`user=${sessionService.getUserGUID()};logs=${logsInString}`})
        if ( sessionService.isLogin() && driverLogs.length > 0 ) {
            client.updateToken(await sessionService.getAccessToken());
            const response = await client.postSaveDriverLogs(driverLogs);
            if (! (response instanceof MSOGAPIError)) {
                await sessionService.removeDriverLogs(driverLogs);
            }
        }
        process.isRunning = false;
    }

    return {run};
}

const uploadLocation = (client) => {

    const run = async (sessionService, process, params) => {
        const appSettings = selectAppSettings(params.currentStore.getState()),
            locationUploadAt = selectLocationUploadAt(params.currentStore.getState()) ?? 0,
            now = Date.now();
        if ( sessionService.isLogin() && sessionService.getDeliveredQueue() !== false &&  now > locationUploadAt + appSettings.gps_tracking_interval * 1000 ) {
            const lat = params.geolocation.latitude,
                long = params.geolocation.longitude;

            if (lat !== null && long !== null) {
                await sessionService.saveLocation(params.geolocation.latitude, params.geolocation.longitude);
            }
            const locations = await sessionService.getLocations();
            if (locations.length > 0) {
                client.updateToken(await sessionService.getAccessToken());
                const response = await client.postSaveLocations(locations);
                if (! (response instanceof MSOGAPIError)) {
                    await sessionService.removeLocations(locations);
                }
            }

            params.currentStore.dispatch(setLocationUploadAt(now));
        }
        process.isRunning = false;
    }

    return {run};
}


export const BackgroundService = (initStore, initClient = null) => {

    const client = initClient ?? new MSOGAPIClient(initStore);
    let sessionService = undefined,
        currentStore = undefined,
        geolocation = undefined;
    let processes = {
        fetchNewJobCount: { method: fetchNewJobCount(client), isRunning: false, params:["currentStore"]},
        uploadDriverLogs: { method: uploadDriverLogs(client), isRunning: false, params: [] },
        uploadLocation: { method: uploadLocation(client), isRunning: false, params: ["currentStore", "geolocation"] },
        uploadErrorLogs: { method: uploadErrorLogs(client), isRunning: false, params: []}
    };
    let isRunning = false;

    return {
        async run() {
            if (isRunning || sessionService === undefined || currentStore === undefined || geolocation === undefined ) return;
            isRunning = true;
            for(const property in processes) {
               const process = processes[property];
               if (! process.isRunning) {
                   process.isRunning = true;
                   let params = {};
                   for(const propertyName of process.params) {
                       params[propertyName] = eval(propertyName);
                   }
                   process.method.run(sessionService, process, params);
               }
            }
            isRunning = false;
        },

        setUnauthorisedHandler(handler) {
            client.setUnauthorisedHandler(handler)
        },

        setSessionService(service) {
            sessionService = service;
        },

        setStore(store) {
            currentStore = store;
        },

        setGeolocation(location) {
            geolocation = location;
        },

        getLatLng() {
            return {lat: geolocation.latitude, lng : geolocation.longitude};
        },

        async forceDriverLogUpload() {
            if (! processes.uploadDriverLogs.isRunning && sessionService !== undefined) {
                processes.uploadDriverLogs.isRunning = true;
                await processes.uploadDriverLogs.method.run(sessionService, processes.uploadDriverLogs, {});
            }
        }
    }
}

const BackgroundServiceContext = createContext({});
export const useBackgroundService = () => useContext(BackgroundServiceContext);

export const BackgroundServiceProvider = ({ children, service}) => {

    const backgroundService = service;
    const {handleUnAuth} = UseLogoutWhenUnAuth();
    service.setUnauthorisedHandler(handleUnAuth);
    service.setSessionService(useSessionService());
    service.setStore(useStore());
    service.setGeolocation(useGeolocation({timeout: 10000}));

    const isOnline = useSelector(selectOnlineState);
    const isLogin = useSelector(selectLoginState);
    const sessionChangedAt = useSelector(selectSessionUpdateTime);
    const isQueueSelected = useSelector(selectQueueSelectedState);
    const isVehicleChecked = useSelector(selectVehicleCheckedState);
    const sessionService = useSessionService();



    useEffect(() => {
        if ( isServiceAlive(isOnline) ){

            let intervalID = -1,
                registeredResult = false;

            const initInterval = async () => {
                //if ((registeredResult = await serviceWorker.registerBackgroundSync()) !== true) {
                    //console.log(registeredResult);
                    intervalID = setInterval(() => {
                        backgroundService.run();
                    }, BACKGROUND_REFRESH_INTERVAL);
                //}
            }

            initInterval();

            return () => {
                if (intervalID > -1) {
                    clearInterval(intervalID);
                }
            };
        }
    }, [isOnline, sessionChangedAt, isQueueSelected, isVehicleChecked]);

    return (
        <BackgroundServiceContext.Provider value={service}>
            {children}
        </BackgroundServiceContext.Provider>
    );
}
